body {
  background-color: slategray;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, input, textarea, select, button {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 500;
}
